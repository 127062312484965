<template>
    <div class="card">
      <div class="card-header pb-0" style="display: flex;">
        <div class="col">
        <h6>Configuración de cadena propia</h6>
        </div>            
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  style="width:60%"
                >
                  Grifos
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  style="width:40%"
                >
                  Nombre Comercial
                </th>                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in lista" :key="index">
                <td :class=item.css>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ item.razon_social }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ item.direccion }}
                      </p>
                      <p class="text-xs text-secondary mb-0">
                        {{ item.ubigeo }}
                      </p>
                    </div>
                  </div>
                </td>
                <td :class=item.css>      
                  <div 
                  v-if="!item.editable"  
                  >            
                    <span class="text-sm" style="font-weight: 600;"                  
                      :class="item.textStyle"
                      >{{ item.nom_comercial }}</span
                    >
                    &nbsp;
                    <i class="fa fa-pencil cursor-pointer" @click="makeEditable(item)" title="Actualizar el nombre comercial"></i>
                  </div>
                  <div 
                  v-else
                  >
                    <div style="width: 80%; display:inline-block;">
                      <oprecom-input
                          type="text"
                          :id="'nameCom-' + item.codigo_osinerg"
                          :name="'nameCom-' + item.codigo_osinerg"
                          :value="item.nom_comercial"                          
                          size="sm"
                        ></oprecom-input>
                    </div>
                    <div style="width: 20%; display:inline-block;">
                      <i class="ms-2 fa fa-check cursor-pointer" @click="confirm(item)" title="Actualizar el nombre comercial"></i>
                      <i class="ms-2 fa fa-close cursor-pointer" @click="cancel(item)" title="Actualizar el nombre comercial"></i>
                    </div>
                  </div>                                    
                </td>                
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </template>
  
  <script>  
  import OprecomInput from "@/components/OprecomInput.vue";

  const url_save_nomComercial = 'https://96rltn8li7.execute-api.us-east-2.amazonaws.com/oprecom/saveNombreComercialPorCodOsi';

  export default {
    name: "cadena-table",
    props: ['lista'],
    data() {        
    },
    methods: {      
      makeEditable(item){        
        item.editable=true;
      },
      confirm(item){
        var nombre = document.getElementById("nameCom-" + item.codigo_osinerg).value;
        if (nombre==""){
          alert("Es necesario el ingreso del Nombre Comercial");
          return;
        }

        this.axios.get(url_save_nomComercial + '?' + "codOsi="+item.codigo_osinerg+"&nombre="+nombre).then(response => {
              var data = response.data;
              if (data=="OK"){
                item.nom_comercial = nombre;
                alert("Registro satisfactorio");
              }else{
                alert("No se pudo registrar el nombre comercial")
              }
              item.editable = false;
            }).catch (e => console.log(e));
      },
      cancel(item){
        item.editable=false;
      },              
    },
    watch: {
    },
    components: {
      OprecomInput    
    },
  };
  </script>
  