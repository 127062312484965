import { createRouter, createWebHistory } from "vue-router";
import Inicio from "../views/Inicio.vue";
// Alertas
import AlertaInicio from "../views/Alerta/Inicio.vue";
// Analitica
import AnaliticaInicio from "../views/Analitica/Inicio.vue";
import AnaliticaMapa from "../views/Analitica/Mapa.vue";
import AnaliticaTabular from "../views/Analitica/Tabular.vue";
import AnaliticaCompetencia from "../views/Analitica/Competencia.vue";
// Cadena
import CadenaInicio from "../views/Cadena/Inicio.vue";
// Calculador
import CalculadoraInicio from "../views/Calculadora/Inicio.vue";
// Competencia
import CompetenciaInicio from "../views/Competencia/Inicio.vue";
// Grifos
import GrifosInicio from "../views/Grifos/Inicio.vue";
// Precios
import PreciosInicio from "../views/Precios/Inicio.vue";
// Promocion
import PromocionInicio from "../views/Promocion/Inicio.vue";
// Publicidad
import PublicidadInicio from "../views/Publicidad/Inicio.vue";
// Control de Ventas
import ControlInicio from "../views/ControlVentas/Inicio.vue";

import Signin from "../views/Signin.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/inicio",
  },
  {
    path: "/inicio",
    name: "Inicio",
    component: Inicio,
  },
  {
    path: "/alerta/inicio",
    name: "Alerta / Inicio",
    component: AlertaInicio,
  },
  {
    path: "/analitica/inicio",
    name: "Analitíca de Precios / Inicio",
    component: AnaliticaInicio,
  },
  {
    path: "/analitica/mapa",
    name: "Analitíca de Precios / Mapa",
    component: AnaliticaMapa,
  },
  {
    path: "/analitica/tabular",
    name: "Analitíca de Precios / Tabular",
    component: AnaliticaTabular,
  },
  {
    path: "/analitica/competencia",
    name: "Analitíca de Precios / Competencia",
    component: AnaliticaCompetencia,
  },  
  {
    path: "/cadena/inicio",
    name: "Cadena propia / Inicio",
    component: CadenaInicio,
  },
  {
    path: "/calculadora/inicio",
    name: "Calculadora / Inicio",
    component: CalculadoraInicio,
  },
  {
    path: "/competencia/inicio",
    name: "Competencia / Inicio",
    component: CompetenciaInicio,
  },
  {
    path: "/grifos/inicio",
    name: "Grifos afiliados / Inicio",
    component: GrifosInicio,
  },
  {
    path: "/precios/inicio",
    name: "Precios anotados / Inicio",
    component: PreciosInicio,
  },
  {
    path: "/promocion/inicio",
    name: "Promoción Masiva / Inicio",
    component: PromocionInicio,
  },
  {
    path: "/publicidad/inicio",
    name: "Publicidad Masiva / Inicio",
    component: PublicidadInicio,
  },
  {
	  path: "/login",
	  name: "Signin",
	  component: Signin,
  },
  {
    path: "/control/inicio",
    name: "Control de Ventas / Inicio",
    component: ControlInicio,
  },  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
