<template>
    <div class="card">
      <div class="card-header pb-0" style="display: flex;">
        <div class="col">
        <h6>Análisis de competencia</h6>
        </div>
        <!-- <i class="fa fa-save cursor-pointer" @click="saveComp()" title="Confirmar actualización del precio"></i> -->
        <div class="col">
        <img
            :src="`./../images/save.png`"
            @click="saveComp()"
            title="Guardar configuración"
            height="50"
            width="50"
            style="margin-left: 10px; cursor: pointer"
            class="float-end"
            id="idSaveCompetencia"
          />
        </div>    
      </div>
      <div class="card-body px-0 pt-0 pb-2" style="overflow-x: auto;">
        <!-- <oprecom-accordion-group nombre="Productos" :lista="lista" :first="first"/> -->
        <table class="table align-items-center mb-0" id="idCompetenciaGroup">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="width:40%"
                >
                  Grifos
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="width:30%"
                >
                  Productos
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center" style="width:10%"
                >
                  Competencia Estratégica
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center" style="width:10%"
                >
                  Competencia
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="width:10%"
                >
                  Distancia                  
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in lista" :key="index">
                <td :class=item.css style="white-space: normal;">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ item.razon_social }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ item.direccion }}
                      </p>
                    </div>
                  </div>
                </td>
                <td :class=item.css>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ item.productos }}</h6>                      
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center" :class=item.css>
                  <oprecom-radio
                    :id="'ce-' + item.codigo_osinerg"
                    :name="'ce-name'"
                    :value="item.codigo_osinerg"
                    :checked=item.competenciaEst
                    size="sm"
                    @click="validMarkRadio($event)"
                  ></oprecom-radio>
                </td>
                <td class="align-middle text-center" :class=item.css>  
                  <oprecom-checkbox
                    :id="'cm-' + item.codigo_osinerg"
                    :name="'cm-competencia'"
                    :value="item.codigo_osinerg"
                    :checked=item.competencia
                    size="sm"
                    @click="validMarkCheck($event)"
                  ></oprecom-checkbox>                
                </td>
                <td class="align-middle text-center text-sm" :class=item.css>
                  <span class="text-secondary text-xs font-weight-bold">{{
                    (item.distancia / 1000).toFixed(2) + " KM"
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </template>
  
  <script>
  import OprecomCheckbox from "@/components/OprecomCheckbox.vue";
  import OprecomRadio from "@/components/OprecomRadio.vue";

  const url_save_competencia = 'https://jzvksjd74d.execute-api.us-east-2.amazonaws.com/oprecom/saveCompetencia';

  export default {
    name: "competencia-table",
    props: ['lista','codigo_osinerg','first','codigoSel'],
    data() {
        return {
            productos: [],
            codSel: this.codigoSel
        };
    },
    methods: {
      validMarkRadio(event){
        let val = event.target.value;
        // alert(this.codSel+'-'+val);
        if(this.codSel == val){
          this.uncheck(val);
          this.codSel = '';
        }else{
          this.check(val);
          this.codSel = val;
        }
      },
      check(val){
        document.getElementById("ce-" + val).checked = true;
        document.getElementById("cm-" + val).checked = false;
      },
      uncheck(val){
        document.getElementById("ce-" + val).checked = false;
      },
      validMarkRadio_(event){
        let val = event.target.value;
        document.getElementById("cm-" + val).checked = false;        
      },
      validMarkCheck(event){
        let val = event.target.value;
        if (document.getElementById("ce-" + val).checked){
          document.getElementById("cm-" + val).checked = false;
        }
      },
      saveComp(){
        var chks = document.querySelectorAll("input[name='cm-competencia']");
        let cbxMark = '';
        if (document.querySelector('input[name="ce-name"]:checked') != null){
          cbxMark = document.querySelector('input[name="ce-name"]:checked').value;
        }        
        var matChk=[];
        for (var i=0; i<chks.length; i++) {
          if (chks[i].checked){
            matChk[i] = chks[i].value + ":S:N";
          }else{
            let chkMark = chks[i].value + ":N:N";
            if (chks[i].value == cbxMark){
              chkMark = chks[i].value + ":N:S";
            }
            matChk[i] = chkMark;
          }                    
        }
        // console.log(matChk);

        this.axios.get(url_save_competencia + '?' + "codOsi="+this.codigo_osinerg+"&datos="+matChk).then(response => {
          // this.axios.post(url_SavePrecioEnOsinergmin, post).then(response => {
              var data = response.data;
              alert(data);
              // if (data=='0'){
              //   item.precio_venta = this.precio;
              //   alert("El precio fue registrado satisfactoriamente en el sistema PRICE y Facilito del Osinergmin");                
              // }else{
              //   alert("Código "+data+": No se pudo registrar el precio en la PVO de Osinergmin.");
              // }
              // item.editable = false;
            }).catch (e => console.log(e));
      }
    },
    watch: {/* 
        lista : function(newVal) {
            newVal.forEach((item,index) => {
                if(index == 0)
                    this.productos['cod_' + item.cod_producto] = true;
                else
                    this.productos['cod_' + item.cod_producto] = false;
            });
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        }*/
    },
    components: {
      OprecomCheckbox,
      OprecomRadio,
    },
  };
  </script>
  