<template>
    <div class="mx-4 mt-1 mb-3 chart-container">
        <OprecomComboChart 
            ref="comboChart"
            :chartData="chartData" 
            :chartOptions="chartOptions"
        />
    </div>
</template>

<script>
    import OprecomComboChart from '@/components/OprecomComboChart.vue';

    export default {
        name: "combo-chart",
        props: {
            chartData: {
                type: Object,
                required: true
            },
            chartOptions: {
                type: Object,
                default: () => {}
            },
        },
        data() {
            return {                
                
            };
        },
        components: { 
            OprecomComboChart 
        }
    }
</script>
<style scoped>
.chart-container {
  position: relative;
  height: 20rem; /* Ajusta este valor según tus necesidades */
  /* width: 100%; */
}
</style>