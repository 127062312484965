<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Ingrese su n&uacute;mero de RUC y clave para autenticar</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="submit">
                    <div class="mb-3">
                      <oprecom-input type="number" placeholder="RUC" :id="ruc" size="lg" />
                    </div>
                    <div class="mb-3">
                      <oprecom-input type="password" placeholder="Clave" :id="clave" size="lg" />
                    </div>
                    <oprecom-switch id="rememberMe">Recuerdame</oprecom-switch>

                    <div class="text-center">
                      <oprecom-button
                        class="mt-4"
                        variant="gradient"
                        color="oprecom"
                        fullWidth
                        size="lg"
                      >Autenticar</oprecom-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-oprecom h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('./images/login_img.jpg');
          background-size: cover;"
              >
                <span class="mask bg-gradient-oprecom opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Bienvenido a OPRECOM"</h4>
                <p
                  class="text-white position-relative"
                >La aplicaci&oacute;n que le ayudar&aacute; a tomar las mejores decisiones en precios.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import OprecomInput from "@/components/OprecomInput.vue";
import OprecomSwitch from "@/components/OprecomSwitch.vue";
import OprecomButton from "@/components/OprecomButton.vue";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin",
  data(){
    return {
      form: {
        ruc: '',
        clave: '',
      }
    }
  },
  methods: {
	async submit() {
		// console.log(this.form);
		this.$emit('submit', this.form)
	}
  },
  components: {
    OprecomInput,
    OprecomSwitch,
    OprecomButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
