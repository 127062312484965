<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <label :for="id" class="label-agente">
              RUC: {{ ruc }} - {{ razon_social }}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 mb-lg">
            <oprecom-select
              id="cod_producto"
              :list="listProductos"
              optionValue="cod_producto"
              optionText="producto"
              :value="cod_producto"
              @change="changeProducto($event)"
              >Producto:
            </oprecom-select>
          </div>
          <div class="col-lg-7 mb-lg">
            <oprecom-select
              id="codigo_osinerg"
              :list="listGrifos"
              optionValue="codigo_osinerg"
              optionText="descripcion"
              :value="codigo_osinerg"
              @change="changeGrifo($event)"
              >Grifo:
            </oprecom-select>
          </div>
          <div class="col-lg-2 mb-lg">
            <oprecom-select
              id="distance"
              :list="listDistancia"
              optionValue="value"
              optionText="text"
              :value="distance"
              @change="changeDistancia($event)"
              >Distancia:
            </oprecom-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <oprecom-badge size="lg" color="warning"
              >MIN: {{ menor }} SOL / {{ unidad }}
            </oprecom-badge>
            <oprecom-badge size="lg" color="secondary"
              >PROM:{{ promedio }} SOL / {{ unidad }}
            </oprecom-badge>
            <oprecom-badge size="lg" color="success"
              >MAX: {{ mayor }} SOL / {{ unidad }}
            </oprecom-badge>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg">
            <!-- map google -->
            <div class="card z-index-2">
              <center>
                <oprecom-mapa
                  :markers="markers"
                  :distance="distance"
                  :first="first"
                  :center="center"
                  :mayor="mayor"
                  :menor="menor"
                  :codigo_osinerg="codigo_osinerg"
                  :radius="radius"
                />
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OprecomSelect from "@/components/OprecomSelect.vue";
import OprecomBadge from "@/components/OprecomBadge.vue";
import OprecomMapa from "@/components/OprecomMapa.vue";

//const url_list_grifos = 'https://mpkfnbwt6c.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorRucLambda';
//const url_list_productos = 'https://pnifooxpra.execute-api.us-east-2.amazonaws.com/oprecom/getProductoPorCodigoOsinerg';
//const url_list_competencia = 'https://1lcxb41j6f.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorCodProducto';
const url_list_grifos = 'https://ve362kitt3.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorRucLambda';
const url_list_productos = 'https://habzvpwt9h.execute-api.us-east-2.amazonaws.com/oprecom/getProductoPorCodigoOsinerg';
const url_list_competencia = 'https://ksyq96n238.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorCodProducto';

export default {
  name: "inicio",
  data() {
    return {
      distance: 500,
      radius: 500,
      ruc: "",
      codigo_osinerg: "",
      razon_social: "",
      direccion: "",
      listGrifos: [],
      listProductos: [],
      listCompetencia: [],
      cod_producto: "",
      first: {},
      center: { lat: -12.0926578, lng: -77.1218662 },
      markers: [],
      menor: 0,
      promedio: 0,
      mayor: 0,
      unidad: "",
      listDistancia: [
        { value: 500, text: "0.5 KM" },
        { value: 1000, text: "1 KM" },
        { value: 2000, text: "2 KM" },
        { value: 3000, text: "3 KM" },
        { value: 4000, text: "4 KM" },
        { value: 5000, text: "5 KM" },
        { value: 10000, text: "10 KM" },
        { value: 20000, text: "20 KM" },
        { value: 30000, text: "30 KM" },
        { value: 40000, text: "40 KM" }
      ],
    };
  },
  mounted() {
    const keycloak_user = this.$keycloak;
    this.ruc = keycloak_user.tokenParsed.preferred_username;
    this.getGrifosList();
  },
  methods: {
    getGrifosList() {
      this.axios
        .get(url_list_grifos + "?" + "ruc=" + this.ruc).then((response) => {
          var data = response.data;
          this.listGrifos = data;
          if (this.listGrifos.length>0){
            this.first = {
              position: {
                lat: this.listGrifos[0].latitud,
                lng: this.listGrifos[0].longitud,
              },
              codigo_osinerg: this.listGrifos[0].codigo_osinerg,
              razon_social: this.listGrifos[0].razon_social,
              icon: "",
            };
            this.razon_social = this.first.razon_social;

            // Cargar lista de productos
            this.getProductoList();
          }else{
            this.listGrifos = [{codigo_osinerg:'', descripcion:'No existe información'}];
            this.listProductos = [{cod_producto:'', producto:'No existe información'}];
          }
        }).catch((e) => console.log(e));
    },
    getProductoList() {
      this.axios
        .get(
          url_list_productos +
            "?" +
            "codigo_osinerg=" +
            this.first.codigo_osinerg
        )
        .then((response) => {
          var data = response.data;
          this.listProductos = data;

          this.cod_producto = this.listProductos[0].cod_producto;
          this.unidad = this.listProductos[0].unidad;
          this.first.producto = this.listProductos[0];

          // Cargar lista de grifos cercanos
          this.getCompetenciaList();
        })
        .catch((e) => console.log(e));
    },
    getCompetenciaList() {
      this.axios
        .get(
          url_list_competencia +
            "?" +
            "cod_producto=" +
            this.cod_producto +
            "&latitud=" +
            this.first.position.lat +
            "&longitud=" +
            this.first.position.lng +
            "&distancia=" +
            this.distance + "&tipo="
        )
        .then((response) => {
          var data = response.data;
          this.listCompetencia = data;
          this.radius = Number(this.distance);
          this.printPoints();
          this.getPreciosBadges();
        })
        .catch((e) => console.log(e));
    },
    printPoints() {
      var list = this.listCompetencia;
      var nmarkers = [];
      list.forEach((item, index) => {
        nmarkers[index] = {
          position: { lat: item.latitud, lng: item.longitud },
          codigo_osinerg: item.codigo_osinerg,
          razon_social: item.razon_social,
          direccion: item.direccion,
          icon: "",
          producto: {
            cod_producto: item.cod_producto,
            producto: item.producto,
            precio_venta: item.precio_venta,
            unidad: item.unidad,
            marca: item.marca,
            fecha_proceso: item.fecha_proceso,
          },
        };
      });
      this.center = this.first.position;
      this.markers = nmarkers;
      this.codigo_osinerg = this.first.codigo_osinerg;
    },
    changeProducto(event) {
      this.cod_producto = event.target.value;
      this.getCompetenciaList();
    },
    getPreciosBadges() {
      this.markers.forEach((item, index) => {
        var precio = parseFloat(item.producto.precio_venta);
        if (index > 0) {
          if (precio > this.mayor) this.mayor = precio;
          if (precio < this.menor) this.menor = precio;
        } else {
          this.mayor = precio;
          this.menor = precio;
        }
      });
      this.promedio = ((this.mayor + this.menor) / 2).toFixed(2);
      this.codigo_osinerg = this.first.codigo_osinerg;
    },
    changeGrifo(event) {
      this.listGrifos.forEach((item) => {
        var _codigo_osinerg = item.codigo_osinerg;
        if (event.target.value == _codigo_osinerg) {
          this.first = {
            position: {
              lat: item.latitud,
              lng: item.longitud,
            },
            codigo_osinerg: item.codigo_osinerg,
            razon_social: item.razon_social,
            icon: "",
          };
          this.codigo_osinerg = this.first.codigo_osinerg;
        }
      });
      this.getProductoList();
    },
    changeDistancia(event) {
      this.distance = event.target.value;
      this.getCompetenciaList();
    },
  },
  components: {
    OprecomSelect,
    OprecomBadge,
    OprecomMapa,
  },
};
</script>
