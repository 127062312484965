<template>
    <div class="card">
      <div class="card-header pb-0" style="display: flex;">
        <div class="col">
        <h6>Control de Ventas</h6>
        </div>    
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6" v-for="(precVent, index) in lstPrecioVenta" :key="index">
            <combo-chart :chartData="getCharData(precVent)" :chartOptions="getCharOptions(precVent)"/>
          </div>
        </div>
      </div>      
    </div>
  </template>
  
  <script>
  import ComboChart from "./ComboChart.vue";

  export default {
    name: "control-badge",
    props: {
        lstPrecioVenta: {
          type: Object,
          required: true
        }
    },    
    data() {
        return {
          chartData: {
              labels: ['01/05/2024', '02/05/2024', '03/05/2024', '04/05/2024', '05/05/2024', '06/05/2024', '07/05/2024'],
              datasets: [
                  {
                  type: 'bar',
                  label: 'Ventas',
                  backgroundColor: '#004B97',
                  data: [210, 250, 260, 240, 280, 270, 220],
                  yAxisID: 'y-axis-1',
                  order: 2
                  },
                  {
                  type: 'line',
                  label: 'Precio',
                  borderColor: '#FF9900',
                  backgroundColor: '#FF9900',
                  borderWidth: 4,
                  pointBorderWidth: 8,
                  data: [10.25, 11.01, 10.85, 10.85, 10.50, 11.20, 11.05],                        
                  fill: true,
                  yAxisID: 'y-axis-2',
                  order: 1
                  }
              ]
          },
          chartOptions: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                  legend: {
                  display: true,
                  position: 'top',
                  labels: {
                  // This more specific font property overrides the global property
                      font: {
                          size: 12,
                          weight: 'bold'
                      }
                  }
                  },
                  title: {
                  display: true,
                  text: 'Gasohol Plus',
                  padding: {
                      top: 5,
                      bottom: 15
                  },
                  font: {
                      size: 18,
                      weight: 'bold'
                  }
                  }
              },
              scales: {
                  x: {
                      ticks: {
                          font: {
                              size: 10,
                              weight: 'bold'
                          }
                      }
                  }
              },
              'y-axis-1': {
                  type: 'linear',
                  position: 'left',
                  beginAtZero: true,
                  title: {
                      display: true,
                      text: "Galones"
                  },
                  ticks: {
                      callback: function(value) {
                          return value + ' unidades'; // Ajusta la etiqueta del eje Y
                      },
                      font: {
                          size: 12,
                          weight: 'bold'
                      }
                  }                      
              },
              'y-axis-2': {
                  type: 'linear',
                  position: 'right',
                  beginAtZero: true,
                  grid: {
                      drawOnChartArea: false // Esto evita que las líneas del grid se dibujen sobre el área del gráfico
                  },
                  ticks: {
                      callback: function(value) {
                          return value + ' porcentaje'; // Ajusta la etiqueta del eje Y
                      },
                      font: {
                          size: 12,
                          weight: 'bold'
                      }
                  },
                  title: {
                      display: true,
                      text: "Soles/Galón"
                  } 
              }
          }
        };
    },
    methods: {
        getCharData(precVent){
          this.chartData = precVent.chartData;
          return this.chartData;
        },
        getCharOptions(precVent){          
          this.chartOptions = {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                            display: true,
                            position: 'top',
                            labels: {
                            // This more specific font property overrides the global property
                                font: {
                                    size: 12,
                                    weight: 'bold'
                                }
                            }
                            },
                            title: {
                            display: true,
                            text: precVent.producto,
                            padding: {
                                top: 5,
                                bottom: 15
                            },
                            font: {
                                size: 18,
                                weight: 'bold'
                            }
                            }
                        },
                        scales: {
                            x: {
                                ticks: {
                                    font: {
                                        size: 10,
                                        weight: 'bold'
                                    }
                                }
                            }
                        },
                        'y-axis-1': {
                            type: 'linear',
                            position: 'left',
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: "Galones"
                            },
                            ticks: {
                                callback: function(value) {
                                    return value + ' unidades'; // Ajusta la etiqueta del eje Y
                                },
                                font: {
                                    size: 12,
                                    weight: 'bold'
                                }
                            }                      
                        },
                        'y-axis-2': {
                            type: 'linear',
                            position: 'right',
                            beginAtZero: true,
                            grid: {
                                drawOnChartArea: false // Esto evita que las líneas del grid se dibujen sobre el área del gráfico
                            },
                            ticks: {
                                callback: function(value) {
                                    return value + ' porcentaje'; // Ajusta la etiqueta del eje Y
                                },
                                font: {
                                    size: 12,
                                    weight: 'bold'
                                }
                            },
                            title: {
                                display: true,
                                text: "Soles/Galón"
                            } 
                        }
                    }
          return this.chartOptions;
        }
    },
    components: {
      ComboChart
    },
  };
  </script>
  