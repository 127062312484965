<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
		Promocion
      </div>
    </div>
  </div>
  <!-- <div id="app">
    <Heatmap />
  </div> -->
</template>
<script>

// import Heatmap from "@/components/OprecomHeatMap.vue";

export default {
  name: "inicio",
  data() {
    return {

    };
  },
  mounted(){
	
  },
  methods: {

  },
  components: {
    // Heatmap,
  },
};
</script>
