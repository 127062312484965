<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          url="/inicio"
          :class="getRoute() === 'inicio' ? 'active' : ''"
          navText="Inicio"
        >
          <template v-slot:icon>
            <i class="fa fa-home text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/cadena/inicio"
          :class="getRoute() === 'cadena' ? 'active' : ''"
          navText="Cadena propia"
        >
          <template v-slot:icon>
            <i class="fa fa-gas-pump text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/promocion/inicio"
          :class="getRoute() === 'promocion' ? 'active' : ''"
          navText="Promoción Masiva"
        >
          <template v-slot:icon>
            <i class="fa fa-tag text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/publicidad/inicio"
          :class="getRoute() === 'publicidad' ? 'active' : ''"
          navText="Publicidad Masiva"
        >
          <template v-slot:icon>
            <i class="fa fa-car text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/competencia/inicio"
          :class="getRoute() === 'competencia' ? 'active' : ''"
          navText="Competencia"
        >
          <template v-slot:icon>
            <i class="fa fa-user-o text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/analitica/inicio"
          :class="getRoute() === 'analitica' ? 'active' : ''"
          navText="Analitica de Precios"
        >
          <template v-slot:icon>
            <i class="fa fa-bar-chart text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/alerta/inicio"
          :class="getRoute() === 'alerta' ? 'active' : ''"
          navText="Alertas"
        >
          <template v-slot:icon>
            <i class="fa fa-bell text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/grifos/inicio"
          :class="getRoute() === 'grifos' ? 'active' : ''"
          navText="Grifos Afiliados"
        >
          <template v-slot:icon>
            <i class="fa fa-gas-pump text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/precios/inicio"
          :class="getRoute() === 'precios' ? 'active' : ''"
          navText="Precios Anotados"
        >
          <template v-slot:icon>
            <i class="fa fa-pencil text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/calculadora/inicio"
          :class="getRoute() === 'calculadora' ? 'active' : ''"
          navText="Calculadora"
        >
          <template v-slot:icon>
            <i class="fa fa-calculator text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/control/inicio"
          :class="getRoute() === 'control' ? 'active' : ''"
          navText="Control de Ventas"
        >
          <template v-slot:icon>
            <i class="fas fa-hand-holding-usd text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "OPRECOM",
      isActive: "active"
    };
  },
  components: {
    SidenavItem
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
