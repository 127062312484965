<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
		<div class="row">
			<div class="col-lg-12 mb-lg">
			  <label :for="id" class="label-agente">
				RUC: {{ ruc }} - {{ razon_social }}
			  </label>
			</div>
		</div>
		<div class="row">
          <div class="col-lg-7 mb-lg">
			<oprecom-select 
					id="codigo_osinerg" 
					:list="listGrifos" 
					optionValue="codigo_osinerg" 
					optionText="descripcion" 
					:value="codigo_osinerg"
					@change="changeGrifo($event)"
				>Grifo:
			</oprecom-select>
		  </div>
		</div>
		<div class="row">
			<div class="col-lg-12 mb-lg">
				<control-badge :lstPrecioVenta="lstPrecioVenta"/>
			</div>
		</div>
      </div>
    </div>
  </div>
</template>
<script>
import OprecomSelect from "@/components/OprecomSelect.vue";
import ControlBadge from "./components/ControlBadge.vue";

const url_list_grifos = 'https://ve362kitt3.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorRucLambda';
const url_list_PreciosVentas = 'https://uznahcv03a.execute-api.us-east-2.amazonaws.com/oprecom/findControlVentasChart';

export default {
  name: "control_Ventas",
  data() {
    return {
		ruc: "",
		codigo_osinerg: '',
		razon_social: '',
		lstPrecioVenta: [],
		listGrifos: []
    };
  },
  mounted(){
    const keycloak_user = this.$keycloak;
    this.ruc = keycloak_user.tokenParsed.preferred_username;
	this.getGrifosList();
  },
  methods: {
	getGrifosList(){
		this.axios.get(url_list_grifos + '?' + "ruc=" + this.ruc).then(response => {
			var data = response.data;
			this.listGrifos = data;

			this.codigo_osinerg = this.listGrifos[0].codigo_osinerg;
			this.razon_social = this.listGrifos[0].razon_social;
			// console.log(this.codigo_osinerg);
			this.getPreciosVentasList(this.listGrifos[0]);
		}).catch (e => console.log(e));
	},
	getPreciosVentasList(grifo){
		this.axios.get(url_list_PreciosVentas + '?' + "cod_osinerg=" + grifo.codigo_osinerg).then(response => {
			var data = response.data;
			// console.log(data);
			this.lstPrecioVenta = data;			
		}).catch (e => console.log(e));
	},
	changeGrifo(event){
		this.listGrifos.forEach((item) => {
			var _codigo_osinerg = item.codigo_osinerg;
			if(event.target.value == _codigo_osinerg){
				this.codigo_osinerg = _codigo_osinerg;
				this.getPreciosVentasList(item);
			}			
		});
	}
  },
  components: {
	ControlBadge,
	OprecomSelect,
  },
};
</script>
