<template>
  <div class="py-4 container-fluid">
    <div class="row">
		<div class="col-lg-12 mb-lg">
			<label :for="id" class="label-agente">
			RUC: {{ ruc }} - {{ razon_social }}
			</label>
		</div>
	</div>
	<div class=" row">		
		<div class="col-lg-4 mb-lg"> 
			<oprecom-select 
					id="departamento" 
					:list="listUbigeos" 
					optionValue="nombre" 
					optionText="nombre_contador"
					:value="defSelect"
					@change="changeUbigeo($event)"
				>Departamento:
			</oprecom-select>
		  </div>
		<div class="col-lg-6 mb-lg">
			<oprecom-select 
					id="codigo_osinerg" 
					:list="listGrifos" 
					optionValue="codigo_osinerg" 
					optionText="descripcion" 
					:value="codigo_osinerg"
					@change="changeGrifo($event)"
				>Grifo:
			</oprecom-select>
		</div>
		<div class="col-lg-2 mb-lg" style="display: flex; align-items: center;">
			<router-link class="m-0 navbar-brand" to="/analitica/tabular">
				<img
				:src="`./../images/analitica.jpg`"      
				class="imgRedonda"
				title="Analítica de precios"
				/>
		</router-link>
		</div>		
    </div>
	<div class="row">
			<div class="col-lg-12 mb-lg">
				<analitica-comp-table :codigo_osinerg="codigo_osinerg" :lista="productos" :first="first"/>
			</div>
		</div>
  </div>
</template>
<script>
import OprecomSelect from "@/components/OprecomSelect.vue";
import AnaliticaCompTable from "./components/AnaliticaCompTable.vue";

//const url_list_grifos = 'https://mpkfnbwt6c.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorRucLambda';
//const url_list_productos = 'https://pnifooxpra.execute-api.us-east-2.amazonaws.com/oprecom/getProductoPorCodigoOsinerg';
//const url_list_competencia = 'https://1lcxb41j6f.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorCodProducto';
const url_list_grifos = 'https://ve362kitt3.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorRucLambda';
const url_list_productos = 'https://habzvpwt9h.execute-api.us-east-2.amazonaws.com/oprecom/getProductoPorCodigoOsinerg';
const url_list_competencia = 'https://ksyq96n238.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorCodProducto';
const url_list_ubigeos = 'https://jndtj6ln7k.execute-api.us-east-2.amazonaws.com/oprecom/getUbigeoList';

export default {
  name: "tabular",
  data() {
    return {
		zoom: 15,
		distance: 40000,
		ruc: "",
		codigo_osinerg: '',
		razon_social: '',
		defSelect:"-- Todos --",
		productos: [],
		listGrifos: [],
		listProductos: [],
		listUbigeos: [],
		first: {},
		center: {lat: -12.0926578, lng: -77.1218662},
		listDistancia: [
			{value:500, text:'0.5 KM'},
			{value:1000, text:'1 KM'},
			{value:2000, text:'2 KM'},
			{value:3000, text:'3 KM'},
			{value:4000, text:'4 KM'},
			{value:5000, text:'5 KM'},
			{value:10000, text:'10 KM'},
			{value:20000, text:'20 KM'},
			{value:30000, text:'30 KM'},
			{value:40000, text:'40 KM'}
		],
    };
  },
  mounted(){
    const keycloak_user = this.$keycloak;
    this.ruc = keycloak_user.tokenParsed.preferred_username;	
	this.getDptosList();
  },
  methods: {
	getDptosList(){
		this.axios.get(url_list_ubigeos + '?' + "tipo=2&ubigeoSup=0&ruc=" + this.ruc).then(response => {
			var data = response.data;			
			data.unshift({idlugar:'', nombre:this.defSelect, nombre_contador:this.defSelect})
			this.listUbigeos = data;

			this.getGrifosList(this.defSelect);

		}).catch (e => console.log(e));
	},
	getGrifosList(dpto){
		this.axios.get(url_list_grifos + '?' + "ruc=" + this.ruc + "&dpto=" + dpto).then(response => {
			var data = response.data;
			this.listGrifos = data;
			if (this.listGrifos.length>0){
				this.first = {
					position: {
					lat: this.listGrifos[0].latitud,
					lng: this.listGrifos[0].longitud,
					},
					codigo_osinerg: this.listGrifos[0].codigo_osinerg,
					razon_social: this.listGrifos[0].razon_social,
					icon: "",
					distance: this.distance,
					// radius: this.distance,
					radius: 0,
				};

				this.codigo_osinerg = this.listGrifos[0].codigo_osinerg;
				this.razon_social = this.listGrifos[0].razon_social;
				
				this.getProductoList(this.listGrifos[0]);
				document.getElementById('accordionProductos').style.display = 'block';
			}else{				
				this.listGrifos = [{codigo_osinerg:'', descripcion:'No existe información'}];
				document.getElementById('accordionProductos').style.display = 'none';
			}
		}).catch (e => console.log(e));
	},
	getProductoList(grifo){		
		this.axios.get(url_list_productos + '?' + "codigo_osinerg=" + grifo.codigo_osinerg).then(response => {
			var data = response.data;
			this.productos = data;
			
			this.productos.forEach(item => {
				this.getCompetenciaList(item, grifo);
			});
			// console.log(this.productos);
		}).catch (e => console.log(e));
	},
	getCompetenciaList(producto, grifo){
		// console.log("cod_producto=" + producto.cod_producto + "&latitud=" + grifo.latitud + "&longitud=" + grifo.longitud + "&distancia=" + this.distance + "&tipo=C");
		producto['savePrice'] = grifo.save_price;
		this.axios.get(url_list_competencia + '?' + "cod_producto=" + producto.cod_producto + "&latitud=" + grifo.latitud + "&longitud=" + grifo.longitud + "&distancia=" + this.distance + "&tipo=C").then(response => {
			var data = response.data;
			producto['competencia'] = data;
			// var indice = -1;
			producto['competencia'].forEach((item) => {
				var latitud = item.latitud;
				var longitud = item.longitud;
				if(grifo.latitud != latitud || grifo.longitud != longitud){
					item.css = ""
				}
				item.editable = false;
			});
			// if(indice > -1) producto['competencia'].splice(indice, 1);
			producto['competencia'].sort((a,b) => parseFloat(a.precio_venta) - parseFloat(b.precio_venta))
			this.getPreciosBadges(producto);
		}).catch (e => console.log(e));
	},
	getPreciosBadges(producto){
		producto['competencia'].forEach((item, index) => {
			var precio = parseFloat(item.precio_venta);
			if(index > 0){
				if(precio > producto['mayor']) producto['mayor'] = precio;
				if(precio < producto['menor']) producto['menor'] = precio;
			}else{
				producto['mayor'] = precio;
				producto['menor'] = precio;
			}
		});
		producto['promedio'] = ((producto['mayor'] + producto['menor']) / 2).toFixed(2)

		// colores
		producto['competencia'].forEach((item) => {
			var precio = parseFloat(item.precio_venta);
			item.textStyle = 'text-secundary text-sm label-price'
			if(precio == producto['menor'])
				item.textStyle = 'text-warning text-sm label-price';
			if(precio == producto['mayor'])
				item.textStyle = 'text-success text-sm label-price';
		});
	},
	changeGrifo(event){
		this.listGrifos.forEach((item) => {
			var _codigo_osinerg = item.codigo_osinerg;
			if(event.target.value == _codigo_osinerg){
				this.codigo_osinerg = _codigo_osinerg;
				this.getProductoList(item);

				this.first = {
					position: {
					lat: item.latitud,
					lng: item.longitud,
					},
					codigo_osinerg: item.codigo_osinerg,
					razon_social: item.razon_social,
					icon: "",
					distance: this.distance,
					// radius: this.distance,
					radius: 0,
				};
			}			
		});
	},
	changeDistancia(event){
		this.distance = event.target.value;
		this.listGrifos.forEach((item) => {
			var _codigo_osinerg = item.codigo_osinerg;
			// console.log("Antes del IF")
			if(this.codigo_osinerg == _codigo_osinerg){
				this.getProductoList(item);
				// console.log("Dentro de changeDistance")
				this.first = {
					position: {
					lat: item.latitud,
					lng: item.longitud,
					},
					codigo_osinerg: item.codigo_osinerg,
					razon_social: item.razon_social,
					icon: "",
					distance: this.distance,
					// radius: this.distance,
					radius: 0,
				};
			}
		});
	},
	changeUbigeo(event){		
		this.getGrifosList(event.target.value);		
	}
  },
  components: {
	AnaliticaCompTable,
	OprecomSelect,
  },
};
</script>
<style lang="scss" scoped>
.imgRedonda {
    width:60px;
    height:60px;
    border-radius:10px;
	padding: 5px;
	margin-left: 10px; 
	cursor: pointer;
}
.imgRedonda:hover{
	transform: scale(1.1);
}
</style>