<template>
    <Bar 
      id="bar-chart"
      :data="chartData" 
      :options="chartOptions" />
</template>

<script>
import { defineComponent } from 'vue';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS,  Title,  Tooltip,  Legend,  BarElement,  CategoryScale,  LinearScale,  LineElement,  PointElement,  LineController,  BarController} from 'chart.js';

ChartJS.register( Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, LineController, BarController);

export default defineComponent({
  name: 'oprecom-combo-chart',
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {

    };
  }
});
</script>