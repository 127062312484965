<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
		<div class="row">
			<div class="col-lg-12 mb-lg">
			  <label :for="id" class="label-agente">
				RUC: {{ ruc }} - {{ razon_social }}
			  </label>
			</div>
		</div>
		<div class="row">
          <div class="col-lg-7 mb-lg"> 
			<oprecom-select 
					id="departamento" 
					:list="listUbigeos" 
					optionValue="nombre" 
					optionText="nombre_contador"
					:value="defSelect"
					@change="changeUbigeo($event)"
				>Departamento:
			</oprecom-select>
		  </div>
		</div>
		<div class="row">
			<div class="col-lg-12 mb-lg">
				<cadena-table :lista="unidadesCadena"/>
			</div>
		</div>
      </div>
    </div>
  </div>
</template>
<script>
import OprecomSelect from "@/components/OprecomSelect.vue";
import CadenaTable from "./components/CadenaTable.vue";

const url_list_ubigeos = 'https://jndtj6ln7k.execute-api.us-east-2.amazonaws.com/oprecom/getUbigeoList';
const url_list_grifosCadena = 'https://s0gc1n3h9f.execute-api.us-east-2.amazonaws.com/default/findCadenaPorRuc';

export default {
  name: "tabular",
  data() {
    return {
		ruc: "",
		codigo_osinerg: '',
		razon_social: '',
		defSelect:"-- Todos --",
		unidadesCadena: [],
		listUbigeos: [],
		first: {}		
    };
  },
  mounted(){
    const keycloak_user = this.$keycloak;
    this.ruc = keycloak_user.tokenParsed.preferred_username;
	this.getDptosList();
	// this.getCadenaList();
  },
  methods: {
	getDptosList(){
		this.axios.get(url_list_ubigeos + '?' + "tipo=2&ubigeoSup=0&ruc=" + this.ruc).then(response => {
			var data = response.data;			
			data.unshift({idlugar:'', nombre:this.defSelect, nombre_contador:this.defSelect})
			this.listUbigeos = data;

			this.getCadenaList(this.defSelect);

		}).catch (e => console.log(e));
	},	
	getCadenaList(dpto){
		this.axios.get(url_list_grifosCadena + '?' + "ruc=" + this.ruc + "&dpto=" + dpto).then(response => {
			var data = response.data;									
			this.unidadesCadena = data;
			this.unidadesCadena.forEach((item) => {				
				item.editable = false;
			});

		}).catch (e => console.log(e));
	},
	changeUbigeo(event){		
		this.getCadenaList(event.target.value);		
	}
  },
  components: {
	CadenaTable: CadenaTable,
	OprecomSelect,
  },
};
</script>
